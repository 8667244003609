import React from 'react'

import { AccountLayout, AccountSubMenu,
  AccountProfile, AccountNewsletters } from '../../components'

const AccountHomeTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <AccountLayout lang={lang} switcher={page}>
  		<div className="wrapper d-flex flex-column flex-md-row">

  			<AccountSubMenu lang={lang} />

  			<div className="content">
  				<AccountProfile lang={lang} />
  				<AccountNewsletters lang={lang} />
  			</div>

  		</div>
    </AccountLayout>
  )
}

export default AccountHomeTemplate
